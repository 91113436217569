import 'leaflet'
import '../node_modules/leaflet/dist/leaflet.css'
import { GeoSearchControl } from 'leaflet-geosearch'
import AfdProvider from '../lib/leaflet-geosearch/providers/afdProvider'
import '../node_modules/leaflet-geosearch/dist/geosearch.css'
import 'leaflet-gesture-handling'
import '../node_modules/leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import 'leaflet.locatecontrol'
import '../node_modules/leaflet.locatecontrol/dist/L.Control.Locate.css'
import '../lib/leaflet-geosearch/styles.css'

const stockists = {
    'proxxon': require('../data/proxxon-stockists.json')
}

// Map option defaults
const mapOptions = {
    attributionControl: false,
    center: [53.15, -4],
    crs: L.CRS.EPSG3857,
    gestureHandling: true,
    scrollWheelZoom: false,
    zoom: 6,
    minZoom: 4,
    maxZoom: 16,
    zoomControl: false
}

const markerIcon = L.divIcon({
    html: `<svg width="48px" height="48px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 85.333333c-164.949333 0-298.666667 133.738667-298.666667 298.666667 0 164.949333 298.666667 554.666667 298.666667 554.666667s298.666667-389.717333 298.666667-554.666667c0-164.928-133.717333-298.666667-298.666667-298.666667z m0 448a149.333333 149.333333 0 1 1 0-298.666666 149.333333 149.333333 0 0 1 0 298.666666z" fill="#4090fd" /></svg>`,
    className: '',
    iconSize: [48, 48],
    iconAnchor: [24, 43],
    popupAnchor: [0, -38]
})

const dealerMarkerIcon = L.divIcon({
    html: `<svg width="32px" height="32px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 85.333333c-164.949333 0-298.666667 133.738667-298.666667 298.666667 0 164.949333 298.666667 554.666667 298.666667 554.666667s298.666667-389.717333 298.666667-554.666667c0-164.928-133.717333-298.666667-298.666667-298.666667z m0 448a149.333333 149.333333 0 1 1 0-298.666666 149.333333 149.333333 0 0 1 0 298.666666z" fill="#ff0070" /></svg>`,
    className: '',
    iconSize: [32, 32],
    iconAnchor: [16, 28],
    popupAnchor: [0, -24]
})

// Maps
const proxxonMap = L.map('map_proxxon', mapOptions)

// Controls
const search = new GeoSearchControl({
    provider: new AfdProvider({
        selector: 'address/GBR/fastfind',
        serial: '830875',
        password: 'AcW057uO'
    }),
    style: 'bar',
    autoComplete: true,
    autoCompleteDelay: 500,
    maxMarkers: 1,
    marker: {
        icon: markerIcon,
        draggable: false,
    },
    retainZoomLevel: false,
    autoClose: false,
    searchLabel: 'Enter postcode or address...',
    showMarker: true,
    showPopup: true
})

proxxonMap.addControl(search)

L.control.zoom({
    position: 'bottomright'
}).addTo(proxxonMap);

L.control.locate({
    position: 'bottomright',
    setView: 'untilPanOrZoom',
    keepCurrentZoomLevel: true,
    cacheLocation: true,
    showCompass: true,
    drawCircle: true,
    strings: {
      title: 'Show your location'
    }
}).addTo(proxxonMap);

// Proxxon stockist markers
stockists['proxxon'].forEach((stockist) => {
    L.marker([stockist.longitude, stockist.latitude], {
            autoPan: true,
            icon: dealerMarkerIcon,
            title: stockist.name
        })
        .addTo(proxxonMap)
        .bindPopup(popupContent(stockist), {
            autoPan: true,
            autoPanPaddingTopLeft: new L.Point(0, 80)
        })
})

proxxonMap.on('geosearch/showlocation', (e) => {
    proxxonMap.setZoom(8)
})

// L.tileLayer('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(proxxonMap)

L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}@2x?access_token={accessToken}', {
    tileSize: 512,
    maxZoom: mapOptions.maxZoom,
    zoomOffset: -1,
    id: 'mapbox/streets-v12',
    accessToken: 'pk.eyJ1IjoiZGFuZ2liYnNheG0iLCJhIjoiY2xkYnVpOGxwMDRiNTNzbWlhb2gxNmJxcyJ9.wZnIShRT_sN83f3xFUqU-Q'
}).addTo(proxxonMap)

document.addEventListener('DOMContentLoaded', () => {
    document.querySelector('.proxxon-toggle').addEventListener('click', e => {
        e.preventDefault()

        const container = document.getElementById('map_proxxon_container')

        if(container.classList.contains('hidden')) {
            e.target.textContent = e.target.dataset.textShow
            container.classList.remove('hidden')

            proxxonMap.invalidateSize()
        }
        else {
            e.target.textContent = e.target.dataset.textHide
            container.classList.add('hidden')
        }
    })
})

/**
 * Popup content markup
 *
 * @param {object} stockist
 * @returns string
 */
function popupContent(stockist) {
    let telephone, url

    if (stockist.telephone) {
        telephone = `<br>Tel: ${stockist.telephone}<br>`
    }

    if (stockist.url) {
        url = `<br><a href="${stockist.url}">${stockist.url}</a>`
    }

    return `<div class="map-popup">
        <h3 class="text-lg font-medium">${stockist.name}</h3>
        <address>
            ${stockist.address_1}${stockist.address_1 ? '<br>' : ''}
            ${stockist.address_2}${stockist.address_2 ? '<br>' : ''}
            ${stockist.city}${stockist.city ? '<br>' : ''}
            ${stockist.county}${stockist.county ? '<br>' : ''}
            ${stockist.country}${stockist.country ? '<br>' : ''}
            ${stockist.postcode}${stockist.postcode ? '<br>' : ''}
        </address>

        ${telephone}
        ${url}
    </div>`
}
