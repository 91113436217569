import { JsonProvider } from 'leaflet-geosearch'

/**
 * Afd Postcode Plus lookup provider for geosearch
 *
 * @see https://www.afd.co.uk/developers/rest-api/
 */
class AfdProvider extends JsonProvider {
    /**
     * @param {object} options
     */
    constructor(options) {
        super({
            version: 'v1',
            selector: 'address/GBR/fastfind',
            maxquantity: 5,
            password: null,
            serial: null,
            fields: [
                'list',
                'address1',
                'address2',
                'address3',
                'address4',
                'address5',
                'address6',
                'address7',
                'city',
                'town',
                'county',
                'postcode',
                'longitude',
                'latitude'
            ].join('@'),
            ...options
        })
    }

    endpoint({ query, type }) {
        const endpoint = `${this.options.version}/${this.options.selector}`
        const url = `https://api.afd.co.uk/${endpoint}`
        const params = {
            lookup: query,
            format: 'json',
            maxquantity: this.options.maxquantity,
            password: this.options.password,
            serial: this.options.serial,
            fields: this.options.fields
        }

        return this.getUrl(url, params)
    }

    parse({ data }) {
        if (data.Result == '1' && data.ErrorText == '') {
            return data.Item.map((result) => ({
                x: result.Longitude,
                y: result.Latitude,
                label: [
                    result.Address1,
                    result.Address2,
                    result.Address3,
                    result.Address4,
                    result.Address5,
                    result.Address7
                ].filter(Boolean).join(', '),
                bounds: null,
                raw: result
            }))
        }

        return {}
    }
}

export default AfdProvider
